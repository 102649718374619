import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 20px;
  padding: 5px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

export const Search = styled.div`
  background: #FCFCFC;
  min-width: 120px;
  margin-right: 10px;
`

