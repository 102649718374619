import React from "react"

import { Container, Search } from "./styles"

export default function TagsBar() {
  return(
    <Container>
      <select style={{marginLeft: "10px"}}>
        <option>Um</option>
        <option>Tres</option>
        <option>Dois</option>
      </select>
      <Search>
      <input
        className="searchInput"
        type="text"
        aria-label="Search"
        placeholder="Pesquisar"
      />
      </Search>
    </Container>
  )
}